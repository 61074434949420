import React, {useEffect, useState} from 'react';
import {httpGet} from "../../services/axios-service";
import {urlParams} from "../../services/utils";
import DownloadGroup from "./download-group";


export const SCardInfo = (props) => {
    console.log('render', '--SCardInfo');
    const [preview, setPreview] = useState({});
    let par = urlParams(props?.location?.search);

    useEffect(() => {

        httpGet(Object.assign(par, {sqlScript: 'select * from trg_tenders_detail_tbl where cod=:tid and cod1 = 0'})).then(items => {
            console.log(items, '--loading data ok');
            setPreview(items[0])
        }, err => console.error(err, '--loading data error'))
    }, []);

    return <div className="block">
        {preview && <figure className="image is-3by2">
            <img src={preview.DATA} alt=""/>
        </figure>}
        <br/>
        <DownloadGroup {...par}/>
    </div>

};
