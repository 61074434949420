import React, {memo} from 'react';
import {docItems} from "../../data";
import {SCard} from "../SCard";

const SDocs = () => {
    console.log('SDocs', '--render');

    return <div className="block">
        <div className="columns is-multiline">
            {docItems.map((it, i) => <a className="column is-one-quarter" key={i} href={'/docs/' + it.file} download>
                <SCard title={it["title_ru"]}
                       img="https://442fz.volganet.ru/025227/current/anketa-poluchatelya-sotsialnykh-uslug/%D0%B8%D0%BA%D0%BE%D0%BD.png">
                    <div className="is-size-5">{it.info}</div>
                </SCard>
            </a>)
            }
        </div>
    </div>
};

export default memo(SDocs)