export const docItems = [
    {file: 'cpt0.docx', title_ru: 'Контракт CPT', info: '0%'},
    {file: 'dap0.docx', title_ru: 'Контракт DAP', info: '0%'},
    {file: 'exw8.docx', title_ru: 'Контракт EXW', info: '8%'},
    {file: 'exw8-t.docx', title_ru: 'Контракт EXW', info: '8% (- трансп)'}
];

export const menuItems = [
    {title: "Продаем", pict: '', path: '/market', locked: false},
    {title: "Документы", pict: '', path: '/documents', locked: false},
    {title: "Контакты", pict: '', path: '/contacts', locked: false},
    {title: "Информация", pict: '', path: '/info', locked: false},
    {title: "Ввод тендера", pict: '', path: '/inputs', locked: true},
];
