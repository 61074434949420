import React, {memo} from "react";

const attr = [
    {tip: 'text', img: 'fas fa-book'},
    {tip: 'tel', img: 'fa fa-phone has-text-success'},
    {tip: 'mailto', img: 'fas fa-envelope has-text-info'}];

const icon = {paddingRight: '.3rem'};

const Requisite = ({val = '', info, style}) => {

    let i = 0;
    let res = (val.replace(/\s/g, ''));
    if (+res) {
        i = 1;
        let c = res[0];
        res = (c === '7' || c === '6') ? '0' + res : res;
    } else if (val.indexOf('@') > -1) {
        i = 2;
        res = val;
    } else
        res = '';

    console.log('Requisite', '--render');

    return <a href={attr[i].tip + ":" + res} className="requisite" style={style}>
                <span style={icon}>
                    <i className={attr[i].img}/>
                </span>
        <span className='is-clipped'>{info}</span>
    </a>
};

export default memo(Requisite)
