import {BehaviorSubject} from 'rxjs';
import {httpPost} from "./axios-service";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    }
};

function login(username, password) {
    return httpPost({username: username, password: password}, 'auth').then(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        console.log(user.label, '--logged user');
        currentUserSubject.next(user);
        return user;
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

export function authHeader() {
    const currentUser = authenticationService.currentUserValue;
    return (currentUser && currentUser.token) ? {Authorization: `Bearer ${currentUser.token}`} : {};
}