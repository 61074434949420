import React, {PureComponent} from 'react';
import {authenticationService} from "../../services/authService";


export default class Login extends PureComponent {


    state = {
        username: '',
        password: '',
    };

    handleFiledChange = e => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };

    handleLogin = () => authenticationService.login(this.state.username, this.state.password).then(_ => {
        const {from} = this.props.location.state || {from: {pathname: "/"}};
        this.props.history.push(from);
    });

    handleKeyDown = e => {
        if (e.key === 'Enter') {
            this.handleLogin();
        }
    };

    render() {
        return (
            <div className="section">
                <div className="level" style={boxStyle}>
                    <div className="level-item">
                        <div className="box">
                            <div className="field is-size-2 has-text-info has-text-centered fComic">
                                Sign In
                            </div>
                            <div className="field">
                                <p className="control has-icons-left has-icons-right">
                                    <input className="input is-medium" placeholder="Login"
                                           name="username" autoFocus="" required onChange={this.handleFiledChange}/>
                                    <span className="icon is-medium is-left">
                            <i className="fa fa-user"/>
                          </span>
                                    <span className="icon is-small is-right"><i className="fa fa-check"/></span>
                                </p>
                            </div>
                            <div className="field">
                                <p className="control has-icons-left has-icons-right">
                                    <input className="input is-medium" type="password"
                                           placeholder="Password" name="password" required
                                           onChange={this.handleFiledChange} onKeyDown={this.handleKeyDown}/>
                                    <span className="icon is-medium is-left">
                            <i className="fa fa-lock"/>
                          </span>
                                    <span className="icon is-small is-right">
                            <i className="fa fa-eye"/>
                          </span>
                                </p>
                            </div>

                            <div className="field buttons">
                                <button className="button is-info is-rounded is-outlined is-fullwidth"
                                        onClick={this.handleLogin}>
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
};

const boxStyle = {height: "50vh"}