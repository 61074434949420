import * as React from "react";
import {Component} from "react";
import {httpGet} from "../services/axios-service";
import {urlParams} from "../services/utils";

export function withDbSource(WrappedComponent, params) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {items: []};
        }

        componentDidMount() {
            let par = urlParams(this.props?.location?.search);
            let p = Object.assign(par, params, this.props)
            console.log(p, '--par');
            httpGet(p).then(items => {
                console.log(items, '--items');
                this.setState({items: items})
            }, err => console.error(err, '--withDbSource'))
        }

        render() {
            return <WrappedComponent {...this.state} {...this.props}/>;
        }
    }
}