import React from 'react';
import {Link} from "react-router-dom";
import {withDbSource} from "../../hocs/withDbSource";

const SContacts = ({items}) => {
    console.log('SContacts', '--render');//select * from vprlist_animal where trunc(sysdate) between datas and datae
    return <div className="block">
        {items.map((it, i) => {
            return <Link to={`/details?cod=${it.CLCCODT.id}&type=0`} key={i}>
                <div className="media box" style={{marginBottom: ".3rem"}}>
                    <figure className="media-left">
                        <p className="image" style={{width: "128px", height: "90px"}}>
                            <img src={'/images/elevators/' + it.IMAGE} alt=""/>
                        </p>
                    </figure>

                    <div className="media-content">
                        <div className="content">
                            <p>
                                <strong>{it.CLCCODT.label}</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        })}
    </div>
};

let params = {
    sqlScript: "select cod, label_ru clccodt, image from tweb_univ_name s where type_cod = 0 order by ord",
};

export default withDbSource(SContacts, params);
