export function urlParams(search) {
    if (!search)
        return {};

    let text = search.substr(1);
    let res = {};
    text.split('&').forEach(pair => {
        let arr = pair.split('=');
        res[arr[0]] = arr[1];
    });
    return res;
}