import React, {PureComponent} from 'react';
import SMenu from "./components/menu/menu";
import SFooter from "./components/footer/footer";
import {HashRouter, Route, Switch} from "react-router-dom";
import SMarket from "./components/SMarket";
import SContacts from "./components/SContacts";
import STender from "./components/STender";
import SilosInfo from "./components/SilosInfo";
import {SCardInfo} from "./components/card-Info/card-info";
import Inputs from "./components/inputs/inputs";
import SInfo from "./components/sInfo/sInfo";
import SDocs from "./components/SDocs";
import Login from "./components/login/login";
import {authenticationService} from "./services/authService";
import {PrivateRoute} from "./components/common/private-routes";

export default class App extends PureComponent {

    state = {
        currentUser: null
    };

    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({currentUser: x}));
    }

    render() {
        return <div className="container-fluid">
            <div className="container">
                {<HashRouter>
                    <SMenu/>
                    <Switch>
                        <Route exact path="/"><STender height={18}/></Route>
                        <Route path="/documents" component={SDocs}/>
                        <Route path="/market" component={SMarket}/>
                        <Route path="/contacts" component={SContacts}/>
                        <Route path="/details" component={SilosInfo}/>
                        <Route path="/info" component={SInfo}/>
                        <Route path="/tender" component={SCardInfo}/>
                        <PrivateRoute path="/inputs" component={Inputs}/>
                        <Route path="/login" component={Login}/>
                        <Route render={() => (<h1>404 Not Found</h1>)}/>
                    </Switch>
                    <SFooter/>
                </HashRouter>}
            </div>
        </div>
    }
}
