import React from 'react';

const stateStyle = {
    position: "absolute",
    top: "10%",
    right: "10%",
    fontWeight: "bold",
    textShadow: "black 0.1em 0.1em 0.2em",
    fontSize: "3rem"
};

export const SCard = ({title, sub, state, img, children}) => {
    return <div className="card">
        {
            img && <div className="card-image">
                <figure className="image is-4by3">
                    <img src={img} alt=""/>
                </figure>
                {
                    state !== undefined &&
                    <i className={`fas ${state ? 'fa-check has-text-success' : 'fa-ban has-text-danger'}`}
                       style={stateStyle}/>
                }
            </div>
        }
        <div className="card-content">
            <div className="media">
                <div className="media-content" style={{overflowX: "unset"}}>
                    <p className="title is-5">{title}</p>
                    <p className="subtitle is-6">{sub}</p>
                </div>
            </div>

            <div className="content">
                {children}
            </div>
        </div>
    </div>
};
