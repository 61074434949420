import React, {PureComponent} from "react";
import {Map, Placemark, YMaps} from 'react-yandex-maps';
import {httpGet} from "../../services/axios-service";
import {urlParams} from "../../services/utils";
import Requisite from "./requisite"

const mapStyle = {
    width: "100%",
    height: "100%",
    minHeight: '25rem',
};

const reqStyle = {borderBottom: "1px solid #ededed"}

export default class SilosInfo extends PureComponent {

    state = {
        items: null,
    };

    componentDidMount() {
        const par = urlParams(this.props.location.search);
        par.sqlId = 'TRADE_INFO';

        httpGet(par).then(data => {
            //console.log(data[0].DESC_RU.split('<br>'), 'data');
            data[0].DESC_RU = data[0].DESC_RU.split('<br>');
            //console.log(data[0],' --- ')
            this.setState({items: data[0]})
        });
    }

    render() {
        const {items} = this.state;

        console.log(items, '--render info');

        return <div className="block">
            <div className="container">
                <div className="columns">
                    <div className="column is-two-fifths">
                        {items && <div className="card">
                            <div className="card-image">
                                <figure className="image is-4by3">
                                    <img src={'/images/elevators/' + items.IMAGE} alt={items.LABEL_RU}/>
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content">
                                        <p className="title is-5">{items.CLCCODT.label}</p>
                                    </div>
                                </div>


                                <nav className="panel">
                                    <p className="panel-heading">
                                        Реквизиты
                                    </p>

                                    <Requisite info={items.DESC_RU[0]} style={reqStyle}/>
                                    <Requisite val={items.DESC_RU[1].split(':')[1].trim()} info={items.DESC_RU[1]} style={reqStyle}/>
                                    <Requisite val={items.DESC_RU[2].split(':')[1].trim()} info={items.DESC_RU[2]} style={reqStyle}/>
                                    <Requisite val={items.DESC_RU[3].split(':')[1].trim()} info={items.DESC_RU[3]} style={reqStyle}/>
                                </nav>
                            </div>
                        </div>}
                    </div>
                    <div className="column">
                        <div id="mapc" style={mapStyle}>
                            {items &&
                            <YMaps>
                                <Map defaultState={{center: [items.LAT, items.LNG], zoom: 8}} style={mapStyle}>
                                    <Placemark {...{
                                        geometry: [items.LAT, items.LNG],
                                        properties: {
                                            hintContent: items.CLCCODT.label,
                                            balloonContent: items.CLCCODT.label
                                        },
                                        modules: ['geoObject.addon.balloon', 'geoObject.addon.hint']
                                    }} />
                                </Map>
                            </YMaps>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}


