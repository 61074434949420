import React, {memo} from 'react';

const SFooter = () => {
    console.log('SFooter', '--render');
    let year = new Date().getFullYear();
    return <footer className="footer fBackClr">
        <div className="content has-text-centered ">
            <p className="level">
                <strong className="level-item">Call center: <a href="tel:060002329">+373 60002329</a></strong>
                <strong className="level-item">&copy; <span>{`${document.title} ${year}`}</span></strong>
            </p>
        </div>
    </footer>
};

export default memo(SFooter)