import React, {memo} from 'react';

const marginStyle = {marginBottom: '1rem'};

const SInfo = () => {
    console.log('SInfo', '--render');

    return <div className="block">
        <img src={'images/info1.webp'} alt="" style={marginStyle}/>
        <article className="message is-info">
            <div className="message-header">
                <p>Trans Cargo Terminal" SRL с 20 октября 2017 года предлагает услуги перевалки...</p>
            </div>
            <br/>
            <div className="message-body content">
                {"Уважаемые партнеры, " +
                "Сообщаем вам, что терминал \"Trans Cargo Terminal\" SRL находящийся в свободной экономической зоне" +
                "Международный порт \"Джурджулешты\" с 20 октября 2017 года предлагает услуги" +
                "перевалки зерновых и масличных культур по следующим условиям:" +
                "- цена 10 USD / т" +
                "- бесплатное хранение: 20 дней" +
                "- за период, превышающий 20 дней хранения, будет выставлен счет на сумму 0,07 долл. США за тонну в день" +
                "Для получения более подробной информации свяжитесь со следующими ответственными лицами:" +
                "Михаил Редькин - Директор \"TCT\" SRL, email: mihail.redkin@transoilcorp.com, моб: 079009962" +
                "Наталья Бадан - руководитель исполнительного/операционного отдела группы компаний Trans-Oil," +
                "email: n.badan@transoilcorp.ch, тел: 022889342"}
            </div>
        </article>
    </div>
};

export default memo(SInfo)