import React, {memo, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {menuItems} from "../../data";
import {authenticationService} from "../../services/authService";

function SMenu() {

    const [active, setActive] = useState(false);
    const history = useHistory();
    const burg = useRef();

    const toggleCollapse = () => {
        let el = document.getElementById('burg');

        if (el.offsetParent !== null)
            setActive({active: !active})
    };

    let user = authenticationService.currentUserValue;
    const isShow = (it) => it.locked ? !!user : true;

    const logout = () => {
        authenticationService.logout();
        history.push('/login');
    };

    console.log('SMenu', '--render');
    return <nav className="navbar is-light" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
            <Link className="navbar-item fComic" style={title} to={'/'} onClick={toggleCollapse}>
                <span style={defLetStyle}>T</span>ransoil
            </Link>

            <div role="button" id="burg" ref={burg}
                 className={'navbar-burger burger ' + (active && 'is-active')}
                 aria-label="menu" aria-expanded="false"
                 data-target="navbarBasicExample" onClick={toggleCollapse}>
                <span aria-hidden="true"/>
                <span aria-hidden="true"/>
                <span aria-hidden="true"/>
            </div>
        </div>

        <div id="navbarBasicExample" className={'navbar-menu ' + (active && 'is-active')}>
            <div className="navbar-start">
                {menuItems.map((it, i) => isShow(it) && (
                    it.submenu ? <div key={i} className="navbar-item has-dropdown is-hoverable">
                            <div className="navbar-link">
                                {it['title']}
                            </div>
                            <div className="navbar-dropdown is-boxed">
                                {it.submenu.map((sit, k) => isShow(it) &&
                                    <Link key={k} className="navbar-item" to={sit['path']}
                                          onClick={toggleCollapse}>{sit['title']}</Link>)}
                            </div>
                        </div>
                        : <Link key={i} className="navbar-item" to={it['path']}
                                onClick={toggleCollapse}>{it['title']}</Link>)
                )}
            </div>

            <div className="navbar-end">
                <Link className="navbar-item" to={user ? '#' : '/login'}
                      onClick={user ? logout : toggleCollapse}>
                    <span className="fComic">{user ? user.label : 'Войти'}</span>
                    <span className="icon">
                            <i className={(user ? 'fas fa-sign-out-alt' : 'far fa-user') + ' fa-lg'}/>
                        </span>
                </Link>
            </div>
        </div>
    </nav>
}

const title = {
    fontSize: "1.5rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    padding: ".3rem"
};

const defLetStyle = {color: "orangered"};

export default memo(SMenu)