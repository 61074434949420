export function compress(base64, quality = 0.7) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            let res = canvas.toDataURL('image/webp', quality);
            canvas.remove();
            img.remove();
            console.log(res.length / base64.length, '--compress');
            resolve(res)
        };

        img.onerror = (e) => {
            console.error(e, '--error');
            reject(null)
        }
    });
}

export function base64Type(base64) {
    let start = base64.indexOf(':');
    let end = base64.indexOf(';');
    return base64.substring(start + 1, end);
}

const sizeNames = ['B', 'KB', 'MB', 'GB'];

export function calcSize(bite) {
    for (let i = 0; i < sizeNames.length; i++) {
        if (bite < 1024)
            return Math.round(bite) + ' ' + sizeNames[i];
        bite /= 1024;
    }

    return Math.round(bite) + ' ' + sizeNames[sizeNames.length - 1];
}