import React from 'react';
import {SCard} from "../SCard";
import {withDbSource} from "../../hocs/withDbSource";

const SMarket = (props) => {
    console.log('SMarket', '--render');//select * from vprlist_animal where trunc(sysdate) between datas and datae

    return <div className="block">
        <div className="columns is-multiline">
            <div className="column is-one-quarter">
                {props.items.map((it, i) => {
                    return <SCard key={i} title={it.CLCSCT.label}
                                  img="https://www.seekpng.com/png/detail/214-2143604_pig-images-png.png">
                        <div className="has-text-centered has-text-weight-bold">
                            <div className="has-text-success  is-size-1 level-item">
                                <span style={{padding: '1rem'}}>{it.PRICE}</span>
                                <i className={'is-size-2 has-text-' + (it.TREND && it.TREND > 0 ? 'danger fas fa-long-arrow-alt-up' : 'success fas fa-long-arrow-alt-down')}/>
                            </div>
                            <div className="is-size-5 has-text-weight-bold">лей</div>
                        </div>
                    </SCard>
                })
                }
            </div>
        </div>
    </div>
};

let params = {
    sqlScript: "select * from ( " +
        "select a.*, price - LAG (price,1) OVER (partition by sc ORDER BY datas) trend " +
        "from vprlist_animal a " +
        ") where trunc(sysdate) between datas and datae",
};

export default withDbSource(SMarket, params);