import React, {Fragment} from 'react';
import {SCard} from "../SCard";
import {Link} from "react-router-dom";
import {withDbSource} from "../../hocs/withDbSource";

const STender = ({items, height}) => {
    console.log('STender', '--render');
    return <Fragment>
        {/* <div style={{height: height + 'rem'}}>
            <SPreview/>
        </div>*/}
        <div className="block">
            <div className="columns is-multiline">
                {items && items.map((it) => {
                        return <Link key={it.KEY} className={'column is-one-quarter'} to={`/tender?tid=${it.KEY}`}>
                            <SCard title={it.TITLE_RU} state={it.STATE} img={it.IMG}/>
                        </Link>;
                    }
                )}
            </div>
        </div>
    </Fragment>
};

export default withDbSource(STender, {sqlScript: "select * from trg_tenders_tbl"});