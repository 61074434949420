import React, {PureComponent} from 'react';
import {base64Type, calcSize, compress} from "../../services/file-service";
import {httpGetAll, httpPost} from "../../services/axios-service";


export default class Inputs extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            title: '',
            status: '',
            preview: null,
            files: []
        }
    }

    clearFiles = () => {
        this.setState({files: []});
    };

    changeFile = (e) => {
        e.preventDefault();
        let tName = e.target.name;
        let fReader = new FileReader();
        //console.log(e.target, 'image');

        let list = e.target.files;

        for (let i = 0; i < list.length; i++) {
            let file = list[i];
            fReader.onloadend = () => {
                let r = {target: {name: tName, fName: file.name, value: fReader.result}};
                this.handleFiledChange(r);
            };
            fReader.readAsDataURL(file)
        }

    };

    saveTender = () => {
        console.log(this.state, '--saveTender')

        let p = Object.assign({sqlId: 'TRG_TENDER_INS', key: this.state.id}, this.state);
        delete p.files;

        httpPost(p, 'post').then(_ => {
            this.state.files.forEach((row, cod1) => {
                let dd = {
                    key: this.state.id,
                    cod1: cod1,
                    sqlId: 'TRG_TENDER_DETAIL_INS',
                    title: cod1 === 0 ? '' : row.name,
                    file: row.file
                };
                httpPost(dd, 'post').then(_ => {
                    console.log(row.name, '--saved');
                })
            })
        })
    };

    compressImage(i) {
        let arr = this.state.files.slice();
        compress(arr[i].file).then(res => {
            //console.log(res, '==file');
            arr[i].file = res;
            this.setState({files: arr});
        })
    }

    compressFile(file) {
        compress(file).then(res => {
            console.log(file, '--old file');
            console.log(res, '--new file');
            this.setState({preview: res});
        })
    }

    findTender = () => {
        const {id} = this.state;
        console.log('Find tender by id', id);

        httpGetAll([
            {sqlScript: 'select * from trg_tenders_tbl where key=:key', key: id},
            {sqlScript: 'select * from trg_tenders_detail_tbl where cod=:key order by cod1', key: id}
        ]).then(([head, detail]) => {
            let d = detail.map(row => {
                return {name: row.TITLE || 'NONAME', file: row.DATA}
            });
            this.setState({title: head[0].TITLE_RU, status: head[0].STATE, preview: head[0].IMG, files: d});
        });
    };

    handleFiledChange = (e) => {
        const {name, fName, value} = e.target;
        //console.log({name, fName, value}, 'res');
        if (name === "files") {
            this.state.files.push({name: fName, file: value});
            this.setState({[name]: this.state.files.slice()})
            console.log(this.state, '--state');
        } else
            this.setState({[name]: value});
    };

    render() {
        console.log('Inputs', '--renderer');
        const {preview, title, status, files} = this.state;

        return <div className="block">
            <div className="columns">
                <div className="column is-half">
                    <div className="box">
                        <div className="field">
                            <label className="label">Id</label>
                            <div className="control">
                                <input className="input" type="text" name="id" onChange={this.handleFiledChange}
                                       placeholder="Input id"/>
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Name</label>
                            <div className="control">
                                <input className="input" type="text" name="title" onChange={this.handleFiledChange}
                                       placeholder="Input title" value={title}/>
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">State</label>
                            <div className="control">
                                <label className="radio">
                                    <input type="radio" name="status" value={1} onChange={this.handleFiledChange}
                                           checked={status.toString() === "1"}/>
                                    On
                                </label>
                                <label className="radio">
                                    <input type="radio" name="status" value={0} onChange={this.handleFiledChange}
                                           checked={status.toString() === "0"}/>
                                    Off
                                </label>
                            </div>
                        </div>

                        <hr style={{background: "orange"}}/>

                        <div className="level">
                            <div className="file has-name level-left">
                                <label className="file-label">
                                    <input className="file-input" type="file" name="preview"
                                           onChange={this.changeFile}/>
                                    <span className="file-cta">
                                  <span className="file-icon">
                                    <i className="fas fa-upload"/>
                                  </span>
                                  <span className="file-label">
                                    Choose a preview…
                                  </span>
                                </span>
                                </label>
                            </div>
                            {
                                preview && base64Type(preview).startsWith('image/') &&
                                <button className="button is-danger is-outlined is-small level-right"
                                        onClick={() => this.compressFile(preview)}>Compress
                                    ({calcSize(preview.length)})</button>
                            }
                        </div>


                        <hr style={{background: "burlywood"}}/>

                        <table className="table is-narrow">
                            <tbody>
                            {files.map((row, i) => {
                                return <tr key={i}>
                                    <td>{row.name}</td>
                                    <td>{calcSize(row.file.length)}</td>
                                    <td>{
                                        base64Type(row.file).startsWith('image/') &&
                                        <button className="button is-danger is-outlined is-small"
                                                onClick={() => this.compressImage(i)}>Compress</button>
                                    }</td>
                                </tr>
                            })}
                            </tbody>
                        </table>

                        <div className="level">
                            <div className="file has-name level-left">
                                <label className="file-label">
                                    <input className="file-input" type="file" name="files" onChange={this.changeFile}/>
                                    <span className="file-cta">
                                  <span className="file-icon">
                                    <i className="fas fa-upload"/>
                                  </span>
                                  <span className="file-label">
                                    Choose files…
                                  </span>
                                </span>
                                </label>
                            </div>
                            <button className="button is-danger is-small level-right" onClick={this.clearFiles}>Clear
                            </button>
                        </div>
                        <hr style={{background: "burlywood"}}/>

                        <div className="field-group buttons">
                            <button className="button is-link" onClick={this.findTender}>Find</button>
                            <button className="button is-success" onClick={this.saveTender}>Save</button>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="box">
                        <img src={preview} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    }
};