import axios from "axios";

const ax = axios.create({
    //baseURL: 'https://transoilmarket.com/rds/api',
    baseURL: '/rds/api',
//    baseURL: http://192.168.1.100:8080/rds/api
//    baseURL: http://localhost:8080/api
    responseType: "json"
});

function handleResponse(promise) {
    return promise.then(res => res.data,
        err => {
            console.error(err.response, '--ERROR');
            return Promise.reject(err.response.data.message);
        })
}

export function httpGet(params, url = `query`) {
    return handleResponse(ax.get(url, {params: params}))
}

export function httpPost(params, url = 'post') {
    return handleResponse(ax.post(url, params));
}

export function httpGetAll(arr) {
    return Promise.all(arr.map(row => httpGet(row)));
}


export function remoteIp() {
    return ax.get(`https://api.ipify.org/?format=json`);
}


export function isLoggedIn() {
    return !!loggedUser()
}

export function loggedUser() {
    return getItem('signedUser');
}

export function getToken() {
    return this.loggedUser ? this.loggedUser.token || "" : ""
}

export function getItem(key, def) {
    let res = sessionStorage.getItem(key);
    return res ? JSON.parse(res) : def;
}
