import React from "react";
import {withDbSource} from "../../hocs/withDbSource";


const DownloadGroup = ({items}) => {
    //console.log('SCardInfo', items);
    console.log(items, 'DownloadGroup');

    return items.length ? <div className="buttons">
        {items.map(row => <a key={row.COD1} className="button is-info" href={row.DATA}
                             download={row.TITLE}>{row.TITLE}</a>)}
    </div> : <img style={loadingStyle} src={'images/loading.gif'} alt=''/>
};

export default withDbSource(DownloadGroup, {sqlScript: 'select * from trg_tenders_detail_tbl where cod=:tid and cod1 >0 '})

const loadingStyle = {
    height: '5rem',
    padding: '1rem',
    paddingLeft: '3rem'
};