import {authenticationService} from "../../services/authService";
import React from "react";
import {Redirect, Route} from "react-router-dom";

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            //console.log(props.location, '--return url')
            return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
        }
        return <Component {...props} />
    }}/>
);